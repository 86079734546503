import * as $ from "jquery";

$(function() {

    // $('#agbModal .modal-body').on('scroll', function() {
    //     let scrollOffset = 50; // Increase the vertical scroll point to resolve browser incompatibilities
    //     if (this.scrollHeight <= (this.scrollTop + $('#agbModal .modal-body').innerHeight() + scrollOffset)) {
    //         enableTermsCheckbox();
    //     }
    // });

    function enableTermsCheckbox(){
        $('.box').addClass("boxActive");
        $('.box').removeAttr("title");
        $('#terms_checkbox').removeAttr("disabled");
    }
});


