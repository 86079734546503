export function createModal(title, content) {
    let target = document.querySelector('body');
    let modal = document.createElement('div');
    modal.className = 'modal';
    modal.style.display = 'block';
    modal.innerHTML = `<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content"><div class="modal-header"><h5>${title}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button></div><div class="modal-body"></div></div>
    </div>`;

    modal.querySelector('.modal-body').insertAdjacentElement('beforeend', content);

    target.insertBefore(modal, target.nextSibling);
    target.classList.add('modal-open');

    function closeModal() {
        modal.remove();
    }

    let closeButton = modal.querySelectorAll('.close');

    closeButton.forEach(function (element) {
       element.addEventListener('click', function (event) {
           closeModal();
       });
    });

    modal.addEventListener('click', function (event) {
        if (event.target === modal) {
            closeModal();
        }
    });

    return modal;
}
