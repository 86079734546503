import {createModal} from "./Modal";
import _ from './language';
import * as $ from "jquery";

document.addEventListener('DOMContentLoaded', function (event) {
    let buttons = document.querySelectorAll('.openBlockUser');
    let token = document.querySelector('input[name=_token]').value;

    buttons.forEach(function (button) {

        button.addEventListener('click', function (event) {
            let formular = document.createElement('form');
            formular.setAttribute('method', 'post');
            formular.setAttribute('action', '/searchPerson/blockUnblock');
            formular.innerHTML = `
            <input type="hidden" name="_token" value="${token}">
            <input type="hidden" name="uid" value="${button.dataset.user}">
            <label for="description">${_('blockUserDescription')}
            <input id="description" type="text" name="description" maxlength="32" style="margin-left: 15px">
            </label>
            <div class="d-flex justify-content-center">
            <button class="btn btn-primary form-control" type="submit">${_("blockUserSubmitButton")}</button>
            </div>`;

            let modal = createModal(`${_('blockUserModalTitle')} ${button.dataset.user}`, formular);

            formular.addEventListener('submit', function (event) {
                event.preventDefault();
                modal.remove();
                const data = new FormData(event.target);

                axios.post(formular.getAttribute('action'), data)
                    .then(function (response) {
                        button.setAttribute('title', "");
                        button.setAttribute('disabled', true);
                        button.innerHTML = response.data;
                        button.style.cursor = "default";
                    })
                    .catch(function (error) {
                        if (error.response.data.errors.hasOwnProperty('description')) {
                            let errors = document.createElement('ul');
                            console.log(error.response.data.errors.description);

                            error.response.data.errors.description.forEach(function (element) {
                                let domElement = document.createElement("li");
                                domElement.innerText = element.replace('description', _('blockUserDescription'));
                                errors.appendChild(domElement);
                            });

                            let alertBox = document.createElement("div");
                            alertBox.className = 'alert alert-danger';
                            alertBox.appendChild(errors);

                            document.querySelector('.notifications').appendChild(alertBox);

                            $("html, body").animate({scrollTop: 0}, "slow");
                            $("body").css("cursor", "default");
                        } else {
                            $(".notifications").html(`
                                <div class="alert alert-danger">
                                    <ul>
                                        <li>` + error.response.data + `</li>
                                    </ul>
                                </div>
                            `);

                            $("html, body").animate({scrollTop: 0}, "slow");
                            $("body").css("cursor", "default");
                        }

                    });
            });
        });
    });
});
