const de = require('../language/de.json');
const en = require('../language/en.json');

export default function (translate) {
    const language = document.documentElement.lang;
    if(language === 'de') {
        return de[translate];
    } else {
        return en[translate];
    }
}
