require('bootstrap');
require('./custom/table.js');
require('./custom/register.js');
require('./custom/search.js');
require('./custom/blockUser.js');
require('./custom/ModalHelper.js');


window.axios = require('axios');

const menuButton = document.getElementById('menu-toggler');

if(menuButton != null) {
   menuButton.addEventListener('click', function () {
      document.body.classList.toggle('menu-close');
   });
   document.body.style = 'transition: none;';

   var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
   if (width > 750) {
      document.body.classList.remove('menu-close');
   }
}

