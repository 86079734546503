import * as $ from "jquery";

$(function() {

    const $table = $('.searchresults');
    if (!$table.length) {
        return false;
    }

    $table.on('click', '.unblockUser', function (e) {
        e.preventDefault();
        const route = $(this).attr('data-route');
        const button = $(this);

        $("body").css("cursor", "progress");

        axios.get(route)
            .then(function (response) {
                button.attr('title', "");
                button.attr('disabled', true);
                button.html(response.data);

                $("body").css("cursor", "default");
            })
            .catch(function (error) {
                $(".notifications").html(`
                    <div class="alert alert-danger">
                        <ul>
                            <li>`+error.response.data+`</li>
                        </ul>
                    </div>
                `);

                $("html, body").animate({ scrollTop: 0 }, "slow");
                $("body").css("cursor", "default");
            });
    });
});
